@media(max-width:1600px) {
	.services-outer a.service .splus-icon{top:28%}
	.services-outer a.service h4 {font-size: 24px;line-height: 30px;}
}
@media(max-width:1366px) {
	.proprty-img img{height: 400px!important;}
	.property-grid .property .p-bio h3 {font-size: 24px;}
	.property-grid .property .p-bio a {font-size: 18px;}
	.inner-property-grid .inner-property .p-bio h3 {font-size: 24px;}
	.inner-property-grid .inner-property:hover .p-bio h3 {font-size: 24px;}
}
@media(max-width:1200px) {
	.property-grid .property .p-bio h3 {font-size: 26px;}
	.num-of-proj .counter {width: 50%;}
	.project-image .proj-bio span.proj-num {font-size: 90px;line-height: 100px;}
	.pop-box h2 {font-size: 40px;text-transform: uppercase;}
	.pop-box h2 span {font-size: 64px;color: dimgray;}
	.property-top-shape {height: 170px;}
	.services-outer a.service h4 {font-size: 22px;}
}
@media only screen and (max-width: 1249px){
	.main-map__box {position: absolute!important;}
}
@media(max-width:1024px) {
	.navbar-expand-lg .navbar-nav .nav-link {padding-right: 1.5rem;padding-left: 1.5rem;font-size: 18px;}
	.info-bar .phone-num .content .num {font-size: 16px;color: #fff;font-weight: 500;line-height: 16px;}
	.info-bar .follow {padding: 8px 10px;}
	.top-logo {width: 80px;}
	.property-grid .property .p-bio h3 {font-size: 22px;}
	.property-grid .property .p-bio a {font-size: 18px;}
	.pop-box h2 {font-size: 36px;text-transform: uppercase;}
	.cons-service .content h3 {font-size: 20px;}
	.cons-service {min-height: 280px;}
	.property-top-shape {height: 160px;}
	.specification-lits ul li {font-size: 24px;}
	.proprty-img img{height: 320px!important;}
	 header .navbar-nav .nav-item { width: 160px; }	
	 .property-detail { padding-top: 0; }
}
@media(max-width:991px) {
	.info-bar {padding-top: 15px;padding-bottom: 15px;background-color: #fff;position: relative;width: 88%;z-index: 100;}
	.top-logo {width: 80px !important;}
	.navbar {position: absolute;width: 100%;top: 22px;background-color: #fff;}
	.navbar-dark .navbar-toggler {color: #a22035;border-color: transparent;}
	.navbar-dark .navbar-nav .nav-link.active,.navbar-dark .navbar-nav .show>.nav-link {color: #a22035;}
	.navbar-collapse {background-color: #fff;margin-top: 26px;z-index: 50;}
	.navbar-dark .navbar-toggler-icon {background-image: url("../images/burger-icon.svg");}
	.navbar>.container,.navbar>.container-fluid,.navbar>.container-lg,.navbar>.container-md,.navbar>.container-sm,.navbar>.container-xl,.navbar>.container-xxl {display: flex;flex-wrap:inherit;align-items: center;justify-content: end;}
	header {display: flex;background-color: #fff;}
	header .navbar-nav .nav-item {border-top: 1px solid #f1f1f1;border-right: 0px solid #f1f1f1;border-bottom: 0px solid #000;}
	header.light-header .navbar-nav .nav-item {border-top: 1px solid #f1f1f1;border-right: 0px solid rgb(0, 0, 0);border-bottom: 0px solid rgb(0, 0, 0);}
	header .navbar-nav .nav-item:last-child {border-right: 0px solid #000;}
	.navbar-expand-lg .navbar-nav .nav-link {padding-right: 2rem;padding-left: 2rem;font-size: 18px;text-transform: inherit;color: rgb(0, 0, 0);}
	.info-bar .phone-num .icon {font-size: 50px;color: #000;line-height: 50px;margin-right: 8px;}
	.info-bar .phone-num .content .btn {padding: 0;background: none;border: 0;font-size: 14px;font-style: italic;}
	.info-bar .phone-num .content .num {font-size: 16px;color: #000;font-weight: 500;line-height: 18px;}
	.info-bar .follow {display: flex;align-items: center;justify-content: center;padding: 0px 10px;/*border-left: 1px solid #000;*/}
	.info-bar .follow .text {color: #000;font-size: 14px;}
	.info-bar .phone-num {display: flex;padding-right: 10px;}
	.info-bar .follow .line {/*width: 0px;*/margin-left: 3px;margin-right: 3px;}
	.info-bar .follow a .fa {color: #000;font-size: 22px;padding: 2px;}
	.top-logo {width: 100px;}
	.info-bar .search-field .form-control {border-top-left-radius: 20px;border-bottom-left-radius: 20px;background-color: rgb(255, 255, 255);border-top: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-top-left-radius: 20px;border-bottom-left-radius: 20px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;color: #000;width: 130px;}
	.info-bar .search-field .btn {border-top-right-radius: 20px;border-bottom-right-radius: 20px;border-top-left-radius: 20px;border-bottom-left-radius: 20px;}
	.info-bar .search-field.show-search-box .search-btn { border-top: 1px solid #000;border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;}
	.info-bar .search-field .btn .fa { color: #000; }
	.info-bar .lang {padding-left: 20px;}
	.info-bar .lang .btn {background: none;border: 0;font-size: 20px;color: #000;}
	.info-bar .phone-num .content .btn {color: #000;padding: 0;background: none;border: 0;font-size: 14px;font-style: italic;}
	.project-image .proj-bio p {font-size: 18px;}
	.project-image .proj-bio {padding: 10px 25px 0;	}
	.project-image .proj-bio span.proj-num {font-size: 75px;line-height: 80px;}
	.team-mem-bio .role {font-size: 20px;}
	.team-mem-bio .name {font-size: 18px;}
	.team-member-card.card-odd {margin-top: 62px;}
	.team-member-card.card-even {margin-top: 100px;}
	.quality-sec .title-text {padding-bottom: 20px;}
	.quality-sec .quality p {font-size: 20px;}
	.cta-row {margin-top: 150px;margin-bottom: 70px;}
	.pop-btn {width: 32px;height: 46px;margin-bottom: 15px;}
	.pop-btn.nav {padding: 5px;}
	.pop-content {padding-right: 70px;}
	.pro-det-nav .pro-nav {margin-top: 10px; padding: 10px;}
	.specification-lits h3 {font-size: 24px;}
	.members {padding-top: 0px;}
	.specification-lits ul li {font-size: 18px;}
	.image-demo h3 {font-size: 28px;line-height: 44px;}
	.our-locations h3 {font-size: 64px;}
	.scrollbar ul li {font-size: 16px;}
	footer .follow {flex-direction: column;}
	footer .follow .line {margin: 10px 0;}
	.social-icons {min-width: 110px;text-align: center;display: flex;justify-content: space-around;}
	.copyright { text-align: center!important; }
	footer .text-end { text-align: center!important; }
	.inner-properties-row {padding: 0px 10px;}
	.properties-row {padding: 0px 10px;}
	.res-row { margin: 0; }
	.footer-social-icons .social-icons { min-width: 70px; }
	/* footer Page */
	.about-details { padding-top: 50px; }
	/* About Details Page */	
	header { height: 70px; }
	header .navbar-nav .nav-item { width: 100%; position: relative; }
	header .navbar-nav .nav-item ul { position: relative; width: 100%; display:none; }
	header .navbar-nav .nav-item ul li .nav-link { color: #fff; font-size: 14px; }
	header.scrollUp { transform: translateY(0px);}
	.follow-sec .nav { flex-wrap: inherit; }
	header .navbar-nav .nav-item a i { display: none; }
	header .navbar-nav .nav-item i.fa-angle-down { font-size: 25px; position: absolute; top: 0; right: 0; width: 50px; height: 60px; display: flex; align-items: center; justify-content: center; z-index:9; }
	header .navbar-nav .nav-item:hover i.fa-angle-down { transform: rotateZ(0deg); }
	header .navbar-nav .nav-item.slide-nav ul.menu-drop { display: block; }
	header .navbar-nav .nav-item.slide-nav i.fa-angle-down { transform: rotateZ(180deg); }
	.properties .nav-pills .nav-link { font-size: 16px; min-width: 155px; }
	.fixed-top { min-height: 95px; }
	.light-header.inner-header { min-height: 95px; }
	
	/* cookies css start */
	.cookies-inner-block {
		flex-direction: column;
	}
	.cookies-text {
		margin-bottom: 20px;
	}
	.header.fixed .menu-box {
		display: block;
	}
}
@media(max-width:767px) {
	p {font-size: 16px;}
	.hide-on-tab {display: none !important;}
	.info-bar {padding-top: 10px;padding-bottom: 10px;width: 80%;}
	.navbar {position: absolute;width: 100%;top: 15px;}
	.navbar-collapse {margin-top: 23px;}
	.property-grid .property .p-bio h3 {font-size: 18px;}
	.property-grid .property .p-bio a {font-size: 14px;}
	.cta-row p {font-size: 16px;}
	footer .nav .nav-item .nav-link {font-size: 16px;color: #fff;text-transform: uppercase;}
	.footer-logo {margin-bottom: 20px;}
	.about-hero img {object-fit: cover;height: 100%;}
	.company-project {position: inherit;top: inherit;margin-top: 40px;}
	.image-demo {position: inherit;margin-top: 40px;}	
	.num-of-proj { padding-top: 20px; }
	.image-demo h3 {margin-top: 10px;}
	.image-demo::after {display: none;}
	.team-member-card.card-even {margin-top: 50px;}
	.team-member-card.card-odd {margin-top: 50px;}
	.pop-box h2 {font-size: 26px;}
	.destination-prop .nav .nav-item .nav-link {font-size: 14px;}
	.inner-property-grid .inner-property .p-bio h3 {font-size: 26px;}
	.property-detail .res-logo {width: 130px;}
	.property-detail-table table tr th {font-size: 16px;padding: 12px 10px;}
	.property-detail-table table tr td {font-size: 16px;padding: 14px 10px;}
	.pop-box h2 span {font-size: 48px;}
	a.project-nav-link {font-size: 30px;}
	.property-grid .property .proprty-img img {	height: 250px!important;	}
	/*About Details */
	.about-details h2 {font-size: 50px;margin-bottom: 35px;}
	.about-details h2 span {font-size: 35px;margin-top: -30px;}
	.follow-sec h3 {font-size: 50px;}
	.team-list-dep {margin-bottom: 20px;}
	.about-details-page .follow-sec {padding-top: 40px;}
	.about-details p {font-size: 20px;}
	.about-details .list-inline > li {font-size: 18px;}
	.mission-sec h4 {font-size: 18px;}
	.mission-sec .red-text > h4 {font-size: 18px;}
	.g-recaptcha { transform:scale(0.77); transform-origin:0 0; }
	.about-hero, .carousel.pointer-event { height: 70vh; overflow: hidden; }
	.services-outer h1 { margin-bottom: 0px; }
	.service-row { padding-top: 15px; }
	.project-code-box h5 { padding-left: 50px; }
	.project-code-box h5::after { width: 46px; }
	.quality-sec .quality { padding: 20px 10px; }
	.inner-property-grid .inner-property .p-bio { padding: 10px; }
	.services-outer a.service {	min-height: 100px;}
}
@media(max-width:576px) {
	.carousel-inner { height: 70vh; }
	.carousel-item { height: 70vh; }
	.top-logo {width: 71px !important; max-width: inherit;}
	.navbar {top: 6px;}
	.info-bar {padding-top: 5px;padding-bottom: 5px;width: 82%;}
	.navbar-collapse {margin-top: 18px;}
	.info-bar .lang {padding-left: 10px;}
	.info-bar .search-field .form-control {width: 100px;}
	.navbar-collapse {margin-top: 16px;}
	.property-grid {width: 100%;padding: 2px 4px;}
	.follow-sec h3 {font-size: 50px;}
	.property-top-shape {display: none;}
	.properties-inner {top: 0;margin-bottom: 0;}
	.inner-property-grid {width: 100%;}
	.inner-property-grid .inner-property .p-bio h3 {font-size: 18px;}
	.property-detail .proprty-image .propery-sh-bio p {font-size: 30px;font-weight: 300;}
	.property-detail .proprty-image .propery-sh-bio h3 {font-size: 20px;letter-spacing: 2px;}
	.grid-img {height: 300px;overflow: hidden;}
	.grid-img img {object-fit: cover;height: 100%!important;width: 100%;}
	.our-locations h3 {font-size: 40px;}
	.out-lined-btn {padding: 6px 36px;font-size: 16px;}
	.map-scroll {padding: 40px;position: inherit;top: inherit;left: inherit;transform: inherit;}
	.scrollbar {height: 250px;width: 100%;}
	.our-locations h3 {font-size: 36px;}
	.our-locations h3 {margin-bottom: -60px;}
	.pop-box h2 span {font-size: 36px;}
	.pop-content {padding-right: 52px;}
	.property-detail .res-title h3 {font-size: 20px;letter-spacing: 2px;}
	.search-field {display: none;}
	.info-bar {width: 80%;}
	.cta-row {display: block;margin-top: 80px;margin-bottom: 30px;text-align: center;}
	.cta-row .line {display: none;}
	.property-detail .proprty-image {position: relative;height: 300px;}
	.property-detail .proprty-image img {object-fit: cover;height: 100%;}
	.follow-sec h3 {font-size: 40px;}
	.services-outer {width: 95%;padding: 10px 0px;border-radius: 30px;}
	.project-image .proj-bio span.proj-num {font-size: 50px;}
	.newsletter-pop .modal-body {padding: 1.2rem;}
	.quality-sec .title-text {padding-bottom: 30px;}
	.our-locations {padding-top: 20px;}
	.specification-lits {padding-top: 20px;}
	.pop-box {margin-bottom: 40px;}
	.con-ser-container {padding-bottom: 50px;}
	.pro-det-nav {margin-bottom: 40px;}
	/* header page */
	.info-bar .search-field .btn .fa { color: #000; }
	/* About us page */
	.num-of-proj .counter  { justify-content: center; }
	.about-intro-sec .company-profile .scrollbar { min-height: auto; height: auto; }
	/* About Details */
	.about-details-page .about-hero img {min-height: 300px;}
	.contact-outer { margin: 0 10px; border-radius: 30px; padding: 20px 5px; }
	.services-sec { padding-top: 40px; padding-bottom: 40px; }
	.para.description .table tr { display: flex; flex-direction: column; }
	.our-teams { padding-top: 50px; padding-bottom: 0px; }
	.property-detail { padding-top: 10px; padding-bottom: 20px; }
	.properties-inner { padding-bottom: 20px; padding-top: 20px; }
	/* footer */
	.newsletter { padding-top: 30px; padding-bottom: 30px; }
	/* project detail page */
	.follow-sec { padding-top: 20px; padding-bottom: 20px; }
	.follow-sec p { padding-bottom: 0px; }	
	.light-header.inner-header { min-height: 70px;}
	.property-detail .proprty-image .topic-heading .image-heading { max-width: 300px; }
	div#masnory { height: 300px; width: 100%; }
	.row { --bs-gutter-x: 0; }
	.image-demo h3 {margin-top: 10px; font-size: 20px; line-height: inherit; text-align: center; }
	.image-demo br { display: none; }
	.company-project { margin-top: 0; }
	.about-intro-sec .company-profile .scrollbar h3 { font-size: 26px; }
} 
@media(max-width:480px) {
	.our-teams h2 {font-size: 50px;}
	.about-intro-sec .company-profile .scrollbar p { font-size: 16px; line-height: 32px;}
	.about-intro-sec .company-profile {padding-top: 30px;padding-bottom: 10px;}
	.proj-img {height: 280px;}
	.pop-box-section {padding-top: 144px;}
	a.property-btn {font-size: 12px;}
	.specification-lits ul li {font-size: 14px;}	
	/* contact page */
	.contact-form-section .contact-form { padding: 27px 15px; }
	.contact-form-section .email-project-group { display: flex; flex-direction: column;}
	.contact-form-section .email-group, .contact-form-section .project-group { display: flex; flex-direction: row;}
	.contact-form-section .project-group input { width: auto; }
	.contact-form-section .project-group label { margin-left: 0px; }
	.contact-form-section .email-group, .contact-form-section .project-group { margin-top: 15px; justify-content: space-between; }
	/* Header */
	.fixed-top { min-height: auto; }
}
@media(max-width:320px) {
	.properties .nav-pills .nav-link {margin: 8px;}
	.properties .tab-content h2 {font-size: 30px;margin-top: 50px;margin-bottom: 30px;	}
	.btn-more-prop.btn-outline-dark {padding: 5px 30px;font-size: 16px;line-height: 32px;}
	.quality-sec h2 {font-size: 24px;letter-spacing: 2px;}
	.cta-row .cta-btn .btn {font-size: 1rem;}
	.follow-sec .nav-pills .nav-link {margin: 6px;}
	.follow-sec {padding-top: 60px;padding-bottom: 20px;}
	.newsletter p {font-size: 18px;}
	.newsletter .container .row p::before{display: none;}
	.num-of-proj { justify-content: center;padding-top: 15px;flex-wrap: wrap;}
	.num-of-proj .counter {width: 100%;	margin-top: 15px;}
	.image-demo h3 {margin-top: 20px;}
	.our-teams p {font-size: 18px;}
	.services-outer {padding: 40px 10px;border-radius: 20px;}
	.services-outer a.service h4 {font-size: 18px;}
	.services-outer a.service .splus-icon {height: 40px;width: 40px;}
	.services-outer a.service .service-content {padding: 40px 122px 40px 40px;}
	.services-outer a.service h4 {font-size: 18px;line-height: 24px;letter-spacing: 0;}
	.properties .nav-pills .nav-link { min-width: 140px; }
}