.scrollbar_map {
	width: 100%;
	min-width: 150px;
	overflow-y: scroll;
}
.contacts-popup__image img{
	max-width: 134px;
}
@media only screen and (max-width:767px){
	.best-offers__subtitle{
		margin: 0 auto 30px
	}
}.main-map{
	position: relative
}.main-map__subtitle{
	font-size: 26px;
	line-height: 30px;
	letter-spacing: 2px;
	color: #000;
	text-align: center;
	margin-bottom: 55px
}.main-map__inner{
	position: relative
}.main-map__inner::before{
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 140px;
	background: -webkit-gradient(linear,left top,left bottom,color-stop(1.4%,rgba(255,255,255,.0001)),to(#fff));
	background: linear-gradient(180deg,rgba(255,255,255,.0001) 1.4%,#fff 100%);
	z-index: 2;
	pointer-events: none
}@media only screen and (max-width:1249px){
	.main-map__inner{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch
	}
}@media only screen and (max-width:767px){
	.main-map__inner{
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
}.main-map__box{
	position: absolute;
	top: 320px;
	right: 21%;
	max-width: 340px;
	width: 100%;
	background: #fff;
	-webkit-box-shadow: 0 10px 20px rgba(0,0,0,.15);
	box-shadow: 0 10px 20px rgba(0,0,0,.15);
	z-index: 2;
	padding: 36px 30px
}@media only screen and (max-width:1449px){
	.main-map__box{
		left: 5%
	}
}@media only screen and (max-width:1249px){
	.main-map__box{
		position: static;
		-webkit-box-shadow: none;
		box-shadow: none;
		max-width: 300px
	}
}@media only screen and (max-width:767px){
	.main-map__box{
		display: none
	}
}.main-map__box-title{
	font-size: 26px;
	line-height: 30px;
	letter-spacing: 2px;
	color: #1f1f1f;
	margin-bottom: 40px
}.main-map__box-list{
	max-height: 254px;
	overflow-y: auto
}.main-map__box-item{
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	/*letter-spacing: 1px;*/
	text-transform: uppercase;
	color: #1f1f1f;
	margin-bottom: 22px;
	cursor: pointer;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out
}@media only screen and (min-width:1025px){
	.main-map__box-item:hover{
		color: #a41e34
	}
}.main-map__decore{
	position: relative;
	max-width: 1180px;
	width: 100%;
	margin: 0 auto;
	height: 1px
}.main-map__decore::before{
	content: '';
	height: 80px;
	width: 1px;
	background: #ddbb92;
	position: absolute;
	right: 0;
	top: -70px
}@media only screen and (max-width:1200px){
	.main-map__decore::before{
		display: none
	}
}.contacts-popup{
	max-width: 386px;
	width: 100%
}.contacts-popup__inner{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}.contacts-popup__image{
	max-width: 134px;
	width: 100%;
	margin-right: 18px
}.contacts-popup__content{
	max-width: 204px;
	width: 100%
}.contacts-popup__title{
	font-weight: 300;
	font-size: 22px;
	line-height: 24px;
	letter-spacing: 1px;
	color: #1f1f1f;
	margin-bottom: 10px
}@media only screen and (max-width:1023px){
	.contacts-popup__title{
		font-size: 18px
	}
}.contacts-popup__link{
	position: relative;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #1f1f1f;
	text-decoration: none;
	padding-left: 70px;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out
}.contacts-popup__link::before{
	content: '';
	position: absolute;
	width: 50px;
	height: 1px;
	background: #1f1f1f;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out
}@media only screen and (min-width:1025px){
	.contacts-popup__link:hover{
		padding-left: 45px;
		color: #a41e34
	}.contacts-popup__link:hover::before{
		width: 25px
	}
}@media only screen and (max-width:1023px){
	.contacts-popup__link{
		font-size: 12px;
		padding-left: 0
	}.contacts-popup__link::before{
		display: none
	}
}.map{
	height: 700px;
	width: 100%;
	background: #ccc
}@media only screen and (max-width:1249px){
	.map{
		height: 600px
	}
}@media only screen and (max-width:767px){
	.map{
		height: 400px
	}
}