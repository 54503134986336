button:focus:not(:focus-visible) { outline: none; box-shadow: none; }
.modal-backdrop.show { opacity: 0.7;}
.container { max-width: 1170px; }
/*header style*/.contact-form-section
p {font-size: 20px;font-family: 'Roboto', sans-serif;}
input { outline: 0; }.pro-det-nav .pro-nav
input:focus { outline: 0; }
.fweight600{font-weight: 600}
p.para {font-size: 16px;font-weight: 300;line-height: 30px;}
div.para p { font-size: 16px; font-weight: 300; line-height: 24px; margin: 0px; }
div.para p span { font-size: 16px !important; line-height: 30px; margin: 0px; }
.para div{font-size: 16px;font-weight: 300;line-height: 20px; margin: 0px; }
.para ul li{font-size: 16px;font-weight: 300;line-height: 30px;}
.color-red {color: #a32035;}
.script-text {font-family: 'Autography', Pinyon Script;}
.color-pink {color: crimson}
.bg-pat {background-image: url("../images/acrhi-bg.jpg");}
header .bg-dark {background-color: rgba(0, 0, 0, 0) !important;}
header .navbar-nav .nav-item {border-top: 1px solid #fff;border-right: 1px solid #fff;border-bottom: 1px solid #fff;width: 190px; position: relative;}
header .navbar-nav .nav-item:last-child {border-right: 0px solid #fff;}
.navbar-expand-lg .navbar-nav .nav-link {font-family: 'Roboto', sans-serif;font-weight: 500;font-size: 14px;text-transform: uppercase;color: rgba(255, 255, 255, 1);text-align: center;height: 60px;align-items: center;display: flex;justify-content: center;}
.info-bar {padding-top: 30px;padding-bottom: 30px;}
.info-bar .phone-num {display: flex;padding-right: 20px;}
.info-bar .phone-num .icon {font-size: 50px;color: #fff;line-height: 50px;margin-right: 8px;}
.info-bar .phone-num .content .btn {font-family: 'Aleo', serif;font-weight: 300;padding: 0;background: none;border: 0;font-size: 16px;}
.info-bar .phone-num .content .num {font-family: 'Aleo', serif;font-size: 20px;color: #fff;font-weight: 500;line-height: 22px;}
.info-bar .follow {display: flex;align-items: center;justify-content: center;padding: 8px 0px; border-left: 0px solid #fff;}
.info-bar .follow .text {font-family: 'Comfortaa', cursive;font-weight: 400;color: #fff;font-size: 14px; display:none;}
.info-bar .follow .line {background: #a32035;height: 1px;width: 40px;margin-left: 8px;margin-right: 8px;}
.info-bar .follow a .fa {color: #fff;font-size: 22px;padding: 2px;}
.top-logo {width: 100px;}
.info-bar .search-field .form-control::-webkit-input-placeholder {color: #fff;}
.info-bar .search-field .form-control::-moz-placeholder {color: #fff;}
.info-bar .search-field .form-control:-ms-input-placeholder {color: #fff;}
.info-bar .search-field .form-control:-moz-placeholder {color: #fff;}
.add-bg .info-bar .search-field .form-control::-webkit-input-placeholder {color: #000;}
.add-bg .info-bar .search-field .form-control::-moz-placeholder {color: #000;}
.add-bg .info-bar .search-field .form-control:-ms-input-placeholder {color: #000;}
.add-bg .info-bar .search-field .form-control:-moz-placeholder {color: #000;}
.inner-header .info-bar .search-field .form-control::-webkit-input-placeholder {color: #000;}
.inner-header .info-bar .search-field .form-control::-moz-placeholder {color: #000;}
.inner-header .info-bar .search-field .form-control:-ms-input-placeholder {color: #000;}
.inner-header .info-bar .search-field .form-control:-moz-placeholder {color: #000;}
.info-bar .search-field .form-control {font-family: 'Roboto', sans-serif;text-transform: uppercase;border-top-left-radius: 20px;border-bottom-left-radius: 20px;background-color: rgba(255, 255, 255, 0);border-top: 1px solid #fff;border-left: 1px solid #fff;border-bottom: 1px solid #fff;border-right: 0px solid #fff;border-top-left-radius: 20px;border-bottom-left-radius: 20px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;color: #fff;padding: 5px 10px;line-height: 20px;/*width:140px;*/ display:none; }
.info-bar .search-field .btn {border-top-right-radius: 20px;border-bottom-right-radius: 20px;border-top-left-radius: 20px;border-bottom-left-radius: 20px;background-color: rgba(255, 255, 255, 0);border-top: 1px solid transparent;border-right: 1px solid transparent;border-bottom: 1px solid transparent;border-left: 1px solid transparent;padding: 5px 10px;line-height: 20px;}
.info-bar .lang {padding-left: 20px;}
.info-bar .lang .btn {font-family: 'Roboto', sans-serif;font-weight: 400;padding: 0;background: none;border: 0;font-size: 16px;color: #fff;padding-right: 20px;}
.dropdown-toggle::after {display: none;}
.info-bar .lang .btn i.fa {position: absolute;margin: auto;right: 0;top: 4px;}
.info-bar .lang .btn-group .dropdown-menu {min-width: inherit;padding: 0;border-radius: 0;}
.info-bar .lang .btn-group .dropdown-menu li a { padding: 0.25rem 0.6rem;}
.info-bar .lang .btn-group .dropdown-item:active,.info-bar .lang .btn-group .dropdown-item:hover {background-color: #a41a32;color: #fff;}
/* light-header style */
header.light-header {background-color: rgba(255, 255, 255, 0);}
header.light-header.inner-header {background: #fff;position: sticky;inset: 0;z-index: 91;}
header.light-header .navbar-nav .nav-item {border-top: 1px solid #000;border-right: 1px solid #000;border-bottom: 1px solid #000;}
header.light-header .navbar-nav .nav-item:last-child {border-right: 0px solid #000;}
header.light-header .navbar-expand-lg .navbar-nav .nav-link {color: #000;}
header .navbar-expand-lg .navbar-nav .nav-link:hover {color: #a32035;text-decoration: none;}
header .navbar-nav .nav-item.active > a:after { content:''; position: absolute; width: 90%; left: 0; right: 0; margin: auto; height: 5px; bottom: 0; background-color: #a32035; }
header.light-header .navbar-nav .nav-item.active a:after { content:''; position: absolute; width: 90%; left: 0; right: 0; margin: auto; height: 5px; bottom: 0; background-color: #a32035; }
header.light-header .navbar-nav .nav-item a:after { content:''; position: absolute; width: 90%; left: 0; right: 0; margin: auto; height: 5px; bottom: 0; background-color: #fff; }
header.light-header .navbar-nav .nav-item a:hover:after { content:''; position: absolute; width: 90%; left: 0; right: 0; margin: auto; height: 5px; bottom: 0; background-color: #a32035; }
header.light-header .navbar-nav .nav-item .menu-drop a:after { display: none; }
header.light-header .info-bar .phone-num .icon {color: #000;}
header.light-header .info-bar .phone-num .content .num {color: #000;}
header.light-header .info-bar .follow {border-left: 0px solid #000;}
header.light-header .info-bar .follow .text {color: #000; display: none;}
header.light-header .info-bar .follow a .fa {color: #000;}
header.light-header .info-bar .follow a:hover .fa {color: #a32035;}
header.light-header .info-bar .search-field .form-control {background-color: rgba(255, 255, 255, 0);border-top: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;color: #000;}
header.light-header .info-bar .search-field .btn {background-color: rgba(255, 255, 255, 0);border-top: 1px solid transparent;border-right:  1px solid transparent;border-bottom:  1px solid transparent;border-left:  1px solid transparent;}
header.light-header .info-bar .phone-num .content .btn {color: #000;}
header.light-header .info-bar .lang .btn {color: #000;}
header.light-header .search-field .btn .fa{color: #000;}
.add-bg {background-color: rgba(256, 256, 256, 1) !important;transition: all 0.2s linear;}
header.scrollUp { transform: translateY(-250px); }
header { height: 220px; transition: all 0.5s;}
header .navbar-nav .nav-item ul {position: absolute; width: 100%; padding: 0; list-style: none; background: #000; height: 0; opacity:0; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
/*header .navbar-nav .nav-item ul:before { position: absolute; content: ''; width: 90%; height: 7px; background: #a32035; top: -7px; margin: auto;   left: 0; right: 0; }*/
header .navbar-nav .nav-item:hover ul { height: auto; opacity: 1; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
header .navbar-nav .nav-item ul li { border-bottom: 1px solid #fff; }
header .navbar-nav .nav-item ul li:last-child { border-bottom: 0px solid #fff; }
header .navbar-nav .nav-item ul li .nav-link { height: auto; font-size: 12px; }
header.light-header .navbar-expand-lg .navbar-nav ul li .nav-link { color: #fff; }
header.light-header .navbar-expand-lg .navbar-nav ul li .nav-link:hover { color: #a32035; text-decoration: none; }
header .navbar-nav .nav-item i { display:none; }
header .navbar-nav .nav-item a i { margin-left: 10px; display: block; }
header .navbar-nav .nav-item:hover i { transform: rotateZ(180deg); }
header .search-field.show-search-box .form-control {display:block;}
header .search-field.show-search-box .search-btn { border-top: 1px solid #fff; border-right: 1px solid #fff; border-bottom: 1px solid #fff; border-left: 1px solid #fff;}
header.light-header .search-field.show-search-box .search-btn { border-top:1px solid #000; border-right:1px solid #000; border-bottom:1px solid #000; border-left:1px solid #000;}
/* slider style */
.carousel-item {height: 100vh;background-repeat: no-repeat;background-position: center center;background-size: cover;}
.carousel-item img {filter: grayscale(1);}
.fill {width: 100%;height: 100%;object-position: center;object-fit: cover;}
.carousel-control-next,.carousel-control-prev {position: absolute;top: 50%;bottom: 0;z-index: 1;display: flex;align-items: center;justify-content: center;margin-top: -25px;width: 40px;height: 40px;border-radius: 50%;border: 2px solid #a32035 !important;padding: 0;color: #a32035 !important;text-align: center;background: 0 0;border: 0;opacity: .5;transition: opacity .15s ease;}
.carousel-control-next {right: 50px;}
.carousel-control-prev {left: 50px;}
.carousel-control-prev-icon {background-image: url("../images/slider-prev.svg"); width: 1.5rem; height: 1.5rem;}
.carousel-control-next-icon {background-image: url("../images/slider-next.svg"); width: 1.5rem; height: 1.5rem;}
.carousel-indicators {margin-bottom: 3.5rem;}
.carousel-indicators [data-bs-target] {max-width: 15px;height: 15px;max-height: 15px;margin: 3px;background-color: rgba(0, 0, 0, 0.5);border: 1px solid #fff;border-radius: 50%;opacity: .5;transition: all .3s;}
.carousel-indicators .active {background-color: rgba(255, 255, 255, 1);border: 1px solid #fff;border-radius: 50%;opacity: .5;transition: all .3s;}
.after-banner-shape {width: 100%;background-image: url("../images/banner-bottom-shape.png");background-position: center top;height: 200px;height: 200px;
margin-top: -200px;position: relative;}
/* properties style */
.properties {padding-top: 0px;padding-bottom: 50px;}
.properties .nav-pills .nav-link {font-family: 'Roboto', sans-serif;font-weight: 400;background: 0 0;border: 1px solid #000;border-radius: 20px;font-size: 16px;text-transform:uppercase;color: #000;padding: 5px 15px;margin: 15px;min-width: 180px;}
.properties .nav-pills .nav-link:hover {background: #a32035;border: 1px solid #a32035;color: #fff;}
.properties .nav-pills .nav-link.active {background: #a32035;border: 1px solid #a32035;color: #fff;}
.properties-row {display: flex;flex-wrap: wrap;justify-content: center;align-items: center; padding: 0px 10px; max-width: 1170px; margin: auto;}
.property-grid {width: 50%; padding: 0 15px 30px 15px;}
.property-grid .property {position: relative;}
.property-grid .property .proprty-img {position: relative;}
.property-grid .property .proprty-img img {width: 100% !important;object-fit: cover;}
.property-grid .property .proprty-img::after {content: "";width: 100%;position: absolute;top: 0;bottom: 0;right: 0;left: 0;background: rgb(255,255,255);background:linear-gradient(180deg, rgba(255,255,255,0) 30%, rgba(0,0,0,0.9) 100%);}
.property-grid .property .p-bio {width: 100%;position: absolute;right: 0;left: 0;bottom: 0;z-index: 10;text-align: center;color: #fff;padding: 30px;}
.properties .tab-content h2 {font-family: 'Comfortaa', cursive;font-size: 38px;text-transform: uppercase;text-align: center;margin-top: 40px;margin-bottom: 40px;}
.property-grid .property .p-bio h3 {font-family: 'Comfortaa', cursive;font-size: 24px;text-transform: uppercase;font-weight: 300;padding-bottom: 15px;margin: 0;}
.property-grid .property .p-bio a {font-family: 'Comfortaa', cursive;font-size: 16px;text-transform: uppercase;font-weight: 800;color: #fff;text-decoration: none;}
.btn-more-prop.btn-outline-dark {font-family: 'Comfortaa', cursive;font-weight: 400;padding: 10px 40px 5px 40px;font-size: 22px;line-height: 38px; border-radius: 0 !important; position: relative; }
.btn-more-prop.btn-outline-dark:hover {background-color: #a32035;}
.more-prop-a {position: relative;display: inline-block;}
.more-prop-a::after { content: ''; position: absolute; width: 30px; height: 30px; background: #fff; top: -2px; left: -2px; z-index: 1; }
.more-prop-a:hover::after {display: none;}
/* quality section */
.quality-sec {padding-top: 20px;padding-bottom: 50px;background-image: url("../images/acrhi-bg.jpg");}
.quality-row {padding-top: 40px;}
.quality-row [class*='col-md'] {margin-top: 10px;}
.quality-sec .quality {background-color: #000;font-size: 26px;color: #fff;text-align: center;padding: 40px 10px;height: 100%;transition: all 0.2s linear;}
.quality-sec .title-text {padding-top: 0px;padding-bottom: 80px;}
.quality-sec h2 {color: #a32035;font-size: 34px;text-transform: uppercase;letter-spacing: 4px;}
.quality-sec p {font-family: 'Comfortaa', cursive;font-size: 18px;}
.quality-sec .quality p {font-size: 24px;font-weight: 300;margin: 0;}
.quality-sec .quality a p,.quality-sec .quality a  {
	color: #fff; text-decoration: none
}
.quality-sec .quality:hover {background-color: #a32035;}
/* cta style */
.cta-row {display: flex;justify-content: space-around;margin-top: 150px;margin-bottom: 50px;position:relative;}
.cta-row p{font-size: 22px;font-weight: 300;}
.cta-row > .text {width: 100%;padding-bottom: 20px;}
.cta-row .line {height: 1px;background: #000;width: calc(100% - 470px);position: absolute;right: 190px;}
.cta-row .cta-btn {font-family: 'Comfortaa', cursive !important;flex-grow: 2;white-space: nowrap;}
.cta-row .cta-btn .btn { padding: 10px 40px 5px 40px; font-size: 1.5rem; background: #fff; position: relative; }
.cta-row .cta-btn .btn.btn-outline-dark:hover { color: #fff; background-color: #212529; border-color: #212529; }
/* follow-sec style*/
.follow-sec {padding-top: 80px;padding-bottom: 40px;}
.follow-sec p{font-family: 'Comfortaa', cursive;font-size: 22px;font-weight: 300;margin-top: 0;}
.follow-sec .nav-pills .nav-link {background: 0 0;border: 1px solid rgb(135, 135, 135);border-radius: 20px;font-size: 12px;color: rgb(135, 135, 135);padding: 5px 20px;margin: 15px;}
.follow-sec .nav-pills .nav-link:hover {background: #a32035;border: 1px solid #a32035;color: #fff;}
.follow-sec .nav-pills .nav-link.active {background: #a32035;border: 1px solid #a32035;color: #fff;}
.follow-sec h3 {font-family: 'Roboto', sans-serif;font-size: 60px;font-weight: 400;text-transform: uppercase;background: -webkit-linear-gradient(rgb(111, 111, 111), rgb(244, 244, 244));-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.follow-sec p a {font-family: 'Comfortaa', cursive;color: #a32035;text-decoration: none;}
.follow-sec p a span {font-size: 16px; margin-left: 10px;}
.newsletter {padding-top: 50px;padding-bottom: 50px;background-color: #a32035;position: relative;}
.newsletter::after {content: "";height: 20px;width: 24px;background-image: url("../images/subscribe-triangle.png");background-size: contain;position: absolute;margin-left: -12px;top: 0;left: 50%;z-index: 10;}
.newsletter .subs-news {font-family: 'Roboto', sans-serif;font-weight: 300;display: inline-block;margin: 0;color: #fff;font-size: 18px;text-transform: uppercase;position: relative;cursor: pointer;}
.newsletter .container .row .subs-news::before {content: "";height: 1px;width: 60px;background-color: #fff;position: absolute;left: -70px;top: 50%; -webkit-transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1); transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1);}
footer {background-color: #000;padding-top: 50px;}
footer .nav .nav-item .nav-link {font-family: 'Roboto', sans-serif;font-size: 14px;color: #fff;text-transform: uppercase;}
footer .nav .nav-item .nav-link:hover {color: #a32035;text-decoration: none;}
footer .follow {display: flex;align-items: center;justify-content: center; padding:2px 0 0;}
footer .follow .text {font-family: 'Comfortaa', cursive;color: #fff;font-size: 20px;}
footer .follow .line {background: #a32035;height: 1px;width: 50px;margin-left: 8px;margin-right: 8px;}
footer .follow a .fa {color: #fff;font-size: 26px;padding: 0px 10px}
footer .follow a:hover .fa {color: #a32035;}
.footer-social-icons { background: #464646; display: block; padding: 5px 0;}
.copyright {font-family: 'Roboto', sans-serif;color: gray;font-size: 14px;text-transform: uppercase;}
.copyright a {color: gray;}
.footer-wb-logo {font-family: 'Roboto', sans-serif;color: gray;font-size: 14px;}
.footer-wb-logo a {margin: 0;padding: 0;display: inline-block;vertical-align: text-bottom;}
footer .social-icons a { border-right: 1px solid #a32035; display: inline-block; float: left; }
footer .social-icons a:last-child { border-right: none; }
/* properties page style */
.properties-inner {position: relative;padding-bottom: 50px; padding-top: 50px; /*top: -150px;margin-bottom: -150px;*/}
.inner-properties-row {display: flex;flex-wrap: wrap;justify-content: center;align-items: center;padding: 0px 10px; max-width: 1170px; margin: auto;}
.inner-property-grid {width: 50%;padding: 5px;}
.inner-property-grid .inner-property {position: relative; overflow: hidden;}
.inner-property-grid .inner-property .inner-proprty-img {position: relative; overflow: hidden; max-height: 350px;}
.inner-property-grid .inner-property .inner-proprty-img img { z-index: 0; height: 100%; width: 100%; overflow: hidden; opacity: 1; -webkit-transform: translateZ(0); transform: translateZ(0); -webkit-transition: opacity 1s, -webkit-transform 5s; transition: opacity 1s, -webkit-transform 5s; transition: transform 5s, opacity 1s; transition: transform 5s, opacity 1s, -webkit-transform 5s; will-change: "transform"; transform: translateZ(0); visibility: visible; -webkit-backface-visibility: hidden; }
.inner-property-grid .inner-property:hover img { width: 100%; opacity: 1; -webkit-transform: scale(1.2); transform: scale(1.2); -webkit-transition: opacity 1s, -webkit-transform 15s; transition: opacity 1s, -webkit-transform 15s; transition: transform 15s, opacity 1s; transition: transform 15s, opacity 1s, -webkit-transform 15s; }
.inner-property-grid .inner-property .inner-proprty-img::after {content: "";width: 100%;position: absolute;top: 0;bottom: 0;right: 0;left: 0;background: rgb(255,255,255);background:linear-gradient(180deg, rgba(255,255,255,0) 30%, rgba(0,0,0,0.9) 100%);}
.inner-property-grid .inner-property .p-bio {width: 100%;position: absolute;right: 0;left: 0;bottom: 0;transform: translateY(-30%);z-index: 10;text-align: center;color: #fff;padding: 15px;}
.inner-property-grid .inner-property .p-bio h3 {font-family: 'Comfortaa', cursive; margin:0; font-size: 24px;text-transform: uppercase;font-weight: 300;transition: all 0.5s linear;}
.inner-property-grid .inner-property:hover .p-bio h3{font-size: 24px;font-weight: 300;background-image:linear-gradient(-225deg,#fefeff 0%,#ffffff 29%,#a41a32 67%,#ffffff 100%);background-size: auto auto;background-clip:border-box;background-size: 200% auto;color: #fff;background-clip: text;text-fill-color: transparent;-webkit-background-clip: text;-webkit-text-fill-color:transparent;animation: textclip 2s linear infinite;}
@keyframes textclip {
	to { background-position: 200% center; }
}
.property-top-shape {width: 100%; position: relative;}
a.property-btn { font-family: 'Comfortaa', cursive; font-weight: 600; opacity: 1; background: #000; color: #ffffff; font-size: 14px; position: absolute; padding: 4px 30px 4px 10px; bottom: 0; left: 0; border-top-right-radius: 80px; text-decoration: none; text-transform: uppercase; }
a.btn-explore { font-size: 16px; font-weight: 700; color: #fff; text-decoration: none; margin-top: 10px; display: block; }
.inner-property-grid .inner-property:hover a.btn-explore { color: #a41a32; }
a.btn-explore-box {color: #fff; text-decoration: none;}
/* property-detail inner-page style */
.property-detail {padding-top: 20px;padding-bottom: 50px;}
.property-detail .proprty-image {position: relative;}
.property-detail .proprty-image .propery-sh-bio {width: 100%;padding-top: 50px;padding-bottom: 40px;text-align: center;position: absolute;top: 0;z-index: 10;}
.property-detail .proprty-image .propery-sh-bio h3 {font-family: 'Roboto', sans-serif;font-weight: 200;color: #fff;font-size: 26px;font-weight: 300;letter-spacing: 4px;text-transform: uppercase;}
.property-detail .proprty-image .propery-sh-bio p {color: #fff;font-size: 54px;}
.property-detail .proprty-image .propery-sh-bio a {color: #a32035;font-size: 20px;font-weight: 300;text-decoration: none;}
.property-detail .res-logo {width: 170px;padding-right: 20px;}
.property-detail .res-title {padding-left: 20px; position: relative;}
.property-detail .res-title::before { content: ''; position: absolute; width: 1px; height: 24px; background-color: #000; top: 0; left: 0; bottom: 0; margin: auto; }
.property-detail .res-title h3 {font-family: 'Roboto', sans-serif;font-size: 24px;font-weight: 300;letter-spacing: 5px;margin: 0;text-transform: uppercase;}
.property-title-hr {width: 100px;margin: 0 auto;background: #fff;opacity: 1;}
.para.description ul li {font-family: 'Roboto', sans-serif;font-size: 16px;font-weight: 300;line-height: 25px;margin-top: 3px;margin-bottom: 3px;}
.property-detail .highlights {}
.property-detail .highlights label {font-size: 14px;font-weight: 300;color: grey;}
.property-detail .highlights p {font-size: 14px;font-weight: 500;margin-top: 0;}
.project-code-box h5 { position: relative; padding-left: 160px; margin: 0; color: #a32035;}
.project-code-box h5::after { content:''; position: absolute; width: 156px; height:1px; left:0; bottom:5px; margin: auto; background: #a32035; }
a.project-nav-link {color: #a32035;font-size: 44px;font-weight: 300;text-decoration: none;}
a.project-nav-link:hover{ color: #a71009; text-decoration: none; }
.proj-nav-row {padding-left: 20px;padding-right: 20px;display: flex;justify-content: space-between;}
a.proj-nav {display: inline-block;text-decoration: none;color: gray;line-height: 60px;}
a.proj-nav:hover {color: #a32035;}
.proj-nav img {height: 22px;width: 12px;margin: 15px;}
/* .services page style */
.services-sec {padding-top: 80px;padding-bottom: 80px;}
.services-outer {width: 100%; max-width:1140px; margin: 0 auto;padding: 0px;background-color: #fff;}
.services-outer h1 {font-size: 34px;font-weight: 300;margin-bottom: 30px;border-top: 2px solid #000;display: inline-block;padding-top: 15px;}
.services-outer h2 {margin-bottom: 30px;}
.ser-h2-hr {height: 5px;background: #000;opacity: 1;width: 120px;}
hr.ser-h2-hr:not([size]) {height: 8px;}
.services-outer a.service {display: block; width: 100%; background:#000; color: #fff;text-decoration: none;margin-bottom: 30px;min-height: 160px;position: relative;transition: all 0.2s linear;}
.services-outer a.service h4 {font-size: 24px;letter-spacing: 3px;line-height: 36px;font-weight: 300;text-transform: uppercase;}
.service-row {padding-top: 60px;padding-bottom: 0px;}
.services-outer a.service:hover {color: #fff;background: #a32035;}
.services-outer a.service .service-content {padding: 15px;}
.services-outer a.service .splus-icon {height: 50px;width: 50px;background-image: url("../images/s-plus-icon-black.png");background-size: contain;position: absolute;top: 25%;right: 50px;transform: translateY(-50%);z-index: 10; display:none;}
.services-outer a.service:hover .splus-icon {background-image: url("../images/s-plus-icon-white.png");}
.pop-box-section {padding-top: 300px;}
.pop-box {background-color: rgba(255, 255, 255, 0.9);padding-top: 50px;padding-bottom: 50px;margin-bottom: 100px;}
.pop-content {padding-right: 100px;position: relative;}
.pop-box h2 {font-size: 50px;text-transform: uppercase;}
.pop-box h2 span {font-size: 60px;color: dimgray;}
.pop-control {width: 30px;position: absolute;right: 0;top: 0;}
.pop-btn {width: 24px;height: 30px;margin-bottom: 15px;}
.pop-btn.nav {padding: 8px; width: 30px;}
.pop-btn img {width: 100%;}
.con-ser-container {padding-bottom: 80px;}
.cons-service {min-height: 160px;position: relative;background-color: #000;transition: all 0.2s linear;margin-top: 30px;}
.cons-service .content {width: 100%; padding: 15px;}
.cons-service .content h3 {color: #fff;font-size: 22px;text-transform: uppercase;font-weight: 300;letter-spacing: 2px;}
.cons-service .content h3 span { color: #fff;}
.cons-service .icon {height: 40px;width: 40px;position: absolute;right: 40px;top: 40px; display:none;}
.cons-service:hover {background-color: #a32035}
.con-ser-container a { text-decoration: none;}
/* destination-prop style */
.destination-prop {padding-top: 80px;padding-bottom: 80px;}
.destination-prop h1 {border-top: 2px solid #000;display: inline;padding-top: 15px;text-transform: uppercase;letter-spacing: 1px;font-size: 30px;font-weight: 300;}
.destination-prop .nav {margin-top: 30px;margin-bottom: 20px;}
.destination-prop .nav .nav-item .nav-link {font-size: 18px;text-transform: uppercase;font-weight: 300;color: #000;}
.dest-img {position: relative;padding: 300px 0;overflow: hidden;text-align: center;}
.dest-img > img{position: absolute;top: 0;left: 0;}
.dest-img::after {content: "";background-color: rgba(0, 0, 0, 0.6);position: absolute;top: 0;right: 0;left: 0;bottom: 0;z-index: 10;}
.dest-img .dest-banner-text {max-width: 1200px;margin: 0 auto;position: absolute;bottom: 0;left: 50%;transform: translateX(-50%);z-index: 12;color: #fff;text-align: center;}
.dest-img .dest-banner-text p {color: #fff;}
.regions-acc {padding-top: 80px;padding-bottom: 80px;position: relative;min-height: 800px;}
.regions-acc h3 {color: rgb(135, 135, 135);font-size: 30px;}
.regions-acc .accordion-item {border: 0;}
.regions-acc .accordion-button {font-size: 22px !important;font-weight: 500;text-transform: uppercase;padding: 1.5rem 2.2rem;font-size: 1rem;color: rgb(135, 135, 135);text-align: left;border: 0;}
.regions-acc .accordion-button span {text-transform: capitalize !important;font-weight: 300;display: inline-block;padding-left: 15px;}
.accordion-button::after {position: absolute;left: 0;}
.accordion-button:not(.collapsed) {color: rgb(135, 135, 135);background-color: #ffffff;box-shadow: inset 0 0 0 rgb(0 0 0 / 13%);}
.regions-acc .left-circle-nav {width: 200px;height: 411px;position: absolute;top: 50%;left: 0;transform: translateY(-50%);}
.newsletter-pop {}
.newsletter-pop .modal-header {border: 0;position: absolute; right: 0; z-index: 1;}
.newsletter-pop h5 {text-align: center;text-transform: uppercase;font-size: 26px;letter-spacing: 2px;}
.newsletter-pop .modal-content {border: 0;border-radius: 0;}
.newsletter-pop .modal-content p.para-large {font-size: 16px;line-height: 22px;text-transform: inherit;text-align: center;color: #000;font-weight: 400;}
.newsletter-pop .modal-content p.para-xs {font-size: 12px;line-height: 22px;text-transform: inherit;text-align: center;color: #000;margin-top: 16px;margin-bottom: 16px;}
.newsletter-pop .input-group {margin-top: 10px;margin-bottom: 10px;}
.newsletter-pop .form-control {width: 100%;padding: 0.5rem 0rem;font-size: 1.2rem;font-weight: 400;line-height: 2rem;color: #000;background-color: #fff;background-clip: padding-box;border-top: 0px solid #ffffff;border-left: 0px solid #ffffff;border-right: 0px solid #ffffff;border-bottom: 2px solid #000000;-webkit-appearance: none;-moz-appearance: none;appearance: none;border-radius: 0rem;transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;}
.newsletter-pop .form-check {margin-top: 20px;margin-bottom: 15px;}
.newsletter-pop .form-check label {display: inline-block;font-size: 12px;font-style: italic;}
.form-note {text-align: left !important;line-height: 16px !important;}
.newsletter-pop .modal-body {position: relative;flex: 1 1 auto;padding: 3rem;}
.newsletter-pop .btn {background: #000;border-radius: 0 0 30px 0;font-size: 16px;text-transform: uppercase;padding: 10px 40px;margin-top: 40px;border: 0;}
.grid-img {margin-top: 25px; margin: 0.3em;}
.grid-img img {width: 100%; height: 24em;}
.h40em img {height: 40em !important;}
.pro-det-nav {margin-top: 50px;margin-bottom: 50px;}
.pro-det-nav .pro-nav { display: block; background-color: #000; border: 2px solid #000; font-family: 'Roboto', sans-serif; font-weight: 300; font-size: 18px; color: #fff; text-align: center; padding: 15px 10px; transition: all 0.2s linear; text-decoration: none; }
.out-lined-btn {font-family: 'Roboto', sans-serif;font-weight: 300;font-size: 24px;padding: 5px 70px;border-radius: 0;}
.pro-det-nav .pro-nav:hover {background-color: #fff; color: #a32035; border: 2px solid #a32035;}
.property-detail-table {margin-top: 0px;margin-bottom: 80px;}
.property-detail-table table tr th {font-size: 18px;font-weight: 400;padding: 16px 12px;}
.property-detail-table table tr th a { color: #000; }
.property-detail-table table tr td {font-size: 16px;font-weight: 300;padding: 18px 12px;}
.specification-lits {padding-top: 0px;padding-bottom: 0px;}
.specification-lits h3 { border-bottom: 1px solid #000;  padding-bottom: 15px;}
.specification-lits ul {margin: 0;padding-left: 10px; padding-bottom:30px; list-style: none;}
/*.specification-lits h3 {font-size: 26px;font-weight: 400;margin-bottom: 30px;color: #a32035;text-transform: uppercase;}*/
.specification-lits h3 .accordion-button {font-size: 26px;font-weight: 500;margin-bottom: 0px;color: #a32035; padding:0; box-shadow:none; }
.specification-lits h3 .accordion-button span {font-weight: 300;}
.specification-lits h3 .accordion-button::after { right: 0; }
.specification-lits ul li {font-family: 'Comfortaa', cursive;font-size: 18px;font-weight: 400;margin-top: 8px;margin-bottom: 8px;position: relative;}
.specification-lits ul li::before { content: ''; position: absolute; background-image: url(../images/list-style.png); background-repeat: no-repeat; top: 5px; left: -5px; width: 7px; height: 18px; }
/*.specification-lits ul li::marker { font-size: 10px; }*/
.specification-lits ul li > span {color: #9f9f9f;padding-left: 5px;}
.our-locations {padding-top: 30px;}
.our-locations h3 {font-size: 60px;font-weight: 400;text-transform: uppercase;background: -webkit-linear-gradient(rgb(111, 111, 111), rgb(244, 244, 244));-webkit-background-clip: text;-webkit-text-fill-color: transparent;margin-bottom: -60px;}
.locations-map {margin-top: 50px;}
/* Customize website's scrollbar like Mac OS Not supports in Firefox and IE */
/* total width */
.scrollbar::-webkit-scrollbar {background-color: #fafafa;width: 12px}
/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {background-color: #fafafa}
.scrollbar::-webkit-scrollbar-track:hover {background-color: #f4f4f4}
/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {background-color: #a32035;border-radius: 16px;border: 4px solid #fafafa}
.scrollbar::-webkit-scrollbar-thumb:hover {background-color: #a32035;border: 4px solid #f4f4f4}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {display: none}
/* div box */
.pos-rel {position: relative;}
.map-scroll {padding: 40px;position: absolute;top: 50%;left: 15%;transform: translateY(-50%);z-index: 10;background: #fff;box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.38);-webkit-box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.38);-moz-box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.38);}
.scrollbar {height: 250px;width: 100%;min-width: 250px;overflow-y: scroll;}
.overflow {min-height: 250px}
.scrollbar ul {list-style: none;margin: 0;padding-left: 0;}
.scrollbar ul li {font-size: 20px;text-transform: uppercase;font-weight: 300;margin-top: 8px;margin-bottom: 8px;}
/* about page style */
.about-hero {height: 100vh;overflow: hidden;}
.about-hero img {height:100%; object-fit: cover;object-position: center;}
.about-intro-sec {}
.about-intro-sec .company-profile {padding-top: 80px;padding-bottom: 10px;}
.about-intro-sec .company-profile .scrollbar {width: 100%;min-height: 370px;}
.about-intro-sec .company-profile .scrollbar p {font-size: 18px;line-height: 40px;font-weight: 300;}
.about-intro-sec .company-profile .scrollbar h3 {font-size: 30px;font-weight: 500;}
.company-project {position: relative;top: -40%;}
.project-image {position: relative;}
.project-image img {width: 100%;}
.proj-img { height: 450px; overflow: hidden;}
.proj-img img{ object-fit: cover; height: 100%;}
.project-image::after {content: "";position: absolute;background-color: rgba(0, 0, 0, 0.1);top: 0;left: 0;right: 0;bottom: 0;}
.project-image .proj-bio {font-family: 'Comfortaa', cursive;position: absolute;width: 100%;bottom: 0;padding: 20px 40px 0;z-index: 10;}
.project-image .proj-bio span.proj-num {font-size: 120px;color: #fff;line-height: 120px;}
.project-image .proj-bio p {font-size: 22px;text-transform: uppercase;color: #fff;}
.num-of-proj {display: flex;justify-content: end;align-items: center;padding-top: 50px;}
.num-of-proj .counter {width: 33.333%;}
.num-of-proj .counter {display: flex;align-items: center;padding-right: 20px;}
.num-of-proj .counter .number {font-size: 30px;font-weight: 300;padding: 0 20px;}
.num-of-proj .counter .text {font-size: 14px;text-transform: uppercase;font-weight: 300;border-bottom: 4px solid #a32035;}
.image-demo {position: relative;}
.image-demo h3 {font-family: 'Comfortaa', cursive;font-size: 34px;line-height: 54px;font-weight: 300;text-transform: uppercase;}
.image-demo::after {content: "";width: 120px;height: 5px;background-color: #000;position: absolute;top: 0;right: 0;}
.our-teams {padding-top: 100px;padding-bottom: 100px;}
.our-teams h2 {font-size: 60px;font-weight: 400;text-transform: uppercase;background: -webkit-linear-gradient(rgb(177, 177, 177), rgb(255, 255, 255));-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.our-teams p {font-size: 18px;font-weight: 200;}
.members {padding-top: 20px;}
.team-member-card {position: relative;}
.team-member-card.card-even {margin-top: 180px;}
.team-member-card.card-odd {margin-top: 120px;}
.team-member-card .image {position: relative;}
.team-member-card .image::after {content: "";position: absolute;top: 15px;left: 15px;right: 15px;bottom: 15px;border-style: solid;border-width: 3px;border-image:conic-gradient(rgb(215, 215, 215), rgb(73, 73, 73), rgb(167, 167, 167), rgb(62, 62, 62), rgb(50, 50, 50), rgb(60, 60, 60), rgb(215, 215, 215)) 1;}
.team-member-card .image img {width: 100%;}
.team-mem-bio {position: absolute;left: 0;bottom: 50px;background-color: rgb(63, 63, 63);clip-path: polygon(0 0, 84% 0, 100% 100%, 0% 100%);padding: 10px 40px;color: #fff;}
.team-mem-bio::after {content: "";width: 100%;height: 3px;background: rgb(50, 50, 50);background: linear-gradient(90deg, rgba(50, 50, 50, 1) 0%, rgba(218, 218, 218, 1) 50%,rgba(126, 126, 126, 1) 100%);position: absolute;top: 0;left: 0;right: 0;z-index: 10;}
.team-mem-bio::before {content: "";width: 100%;height: 3px;background: rgb(50, 50, 50);background: linear-gradient(90deg, rgba(50, 50, 50, 1) 0%, rgba(218, 218, 218, 1) 50%,rgba(126, 126, 126, 1) 100%);position: absolute;bottom: 0;left: 0;right: 0;z-index: 10;}
.team-mem-bio .name {color: rgb(187, 187, 187);font-size: 20px;position: relative;}
.team-mem-bio .name::before {content: "";height: 10px;width: 12px;background-image: url("../images/team-mem-arrow.png");position: absolute;left: -20px;top: 10px;}
.team-mem-bio .role {color: rgb(255, 255, 255);font-size: 24px;text-transform: uppercase;font-weight: 500;}
/* Circle nav */
.circle-nav-box{position: relative;z-index: 11;}
.circle-nav-box .circle-logo-img{width: 300px;height: 300px;}
.circle-nav {height: 300px;width: 300px;position: absolute;inset: 0;margin: auto;display: flex;align-items: center;justify-content: center;}
.circle-nav a{width: 100px;height: 100px;background-color: #fff;border-radius: 50%;position: absolute;display: flex;align-items: center;text-align: center;justify-content: center;text-transform: uppercase;font-size: 14px;text-decoration: none;color: #000;}
.circle-nav a:hover{color: #a32035;}
.circle-nav .nav-item-1{top: -126px;left: -65px;}
.circle-nav .nav-item-1:before{content: " ";background-color: white;position: absolute;height: 135px;width: 3px;top: 32px;left: 88px;z-index: -1;transform: rotate(141deg);}
.circle-nav .nav-item-2{top: -100px;right: -100px;}
.circle-nav .nav-item-2:before{content: " ";background-color: white;position: absolute;height: 135px;width: 3px;top: 31px;left: 5px;z-index: -1;transform: rotate(221deg);}
.circle-nav .nav-item-3{right: -220px;}
.circle-nav .nav-item-3:before{content: " ";background-color: white;position: absolute;height: 135px;width: 3px;top: -15px;left: -54px;z-index: -1;transform: rotate(270deg);}
.circle-nav .nav-item-4{right: -87px;bottom: -138px;}
.circle-nav .nav-item-4:before{content: " ";background-color: white;position: absolute;height: 135px;width: 3px;top: -85px;left: -3px;z-index: -1;transform: rotate(139deg);}
.circle-nav .nav-item-5{left: -58px;bottom: -139px;}
.circle-nav .nav-item-5:before{content: " ";background-color: white;position: absolute;height: 135px;width: 3px;top: -70px;right: 0px;z-index: -1;transform: rotate(37deg);}
.circle-nav .nav-item-6{left: -190px;top: 108px;}
.circle-nav .nav-item-6:before{content: " ";background-color: white;position: absolute;height: 135px;width: 3px;top: -15px;left: 122px;z-index: -1;transform: rotate(90deg);}
/* contact style */
.contact-outer { max-width: 1140px; margin: 0 auto; border-radius: 60px; padding: 40px 20px; background-color: rgba(255, 255, 255, 0.8); box-shadow: 0px 1px 15px 4px rgb(0 0 0 / 10%); -webkit-box-shadow: 0px 1px 15px 4px rgb(0 0 0 / 10%); -moz-box-shadow: 0px 1px 15px 4px rgba(0, 0, 0, 0.1); }
.contact-outer h1 { font-size: 34px; font-weight: 300; margin-bottom: 24px; border-top: 2px solid #000; display: inline-block; padding-top: 15px; }
.contact-outer p { margin-bottom: 40px; }
.contact-outer .btn { background: #a32035; border: 1px solid #a32035; color: #fff; border-radius: 40px; width: 150px; margin: 0 auto; }
.form-control:focus { border-color: #a320352b; box-shadow: 0 0 0 0.25rem #a3203529; }
.main-map__box { top: inherit !important; padding: 30px 20px !important; bottom: 10%; }
.main-map__box-title { margin-bottom: 20px !important; }
.main-map__box-item { margin-bottom: 10px !important; }
.contact-address { background-color: #fafafa; padding: 15px 15px 5px; border-radius: 10px; }
.contact-address h3 { font-size: 18px; margin-bottom: 8px; }
.contact-address p { font-size: 14px; margin-bottom: 25px; }
.contact-address p a { color: #a32035 }
.contact-outer .form-check { padding-left: 0; }
/*Box Till Animation*/
.properties .properties-row .property-grid .property:hover .proprty-img { -webkit-transform: perspective(500px) rotateY(-5deg); transform: perspective(500px) rotateY(-5deg); -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.properties .properties-row .property-grid:last-child .property { margin-bottom: 0px; }
.properties .properties-row .property-grid .property .proprty-img { -webkit-transform: inherit; transform: inherit; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.properties .properties-row .property-grid .property .proprty-img:before { width: 92%; height: 92%; left: -14px; bottom: -14px; background: rgba(0,2,72,.1); content: ""; position: absolute; z-index: -1; transform-style: preserve-3d; transform: rotateY(-10deg); filter: blur(25px); -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.properties .properties-row .property-grid .property:hover .proprty-img:before { opacity: 0; }
.properties .properties-row .property-grid .property .proprty-img .block_01 .block_image,
.blocks_366.our-services-home .main_content_wrapper .block_01 .content_heading { position: relative; z-index: 2; }
.properties .properties-row .property-grid .property:hover .proprty-img .block_01:before { -webkit-transform: inherit; transform: inherit; z-index: 0; top: 10px; right: 0; width: calc(100% + 0px); height: calc(100% + 0px); }
.properties .properties-row .property-grid .property .proprty-img .block_01 .block_image a img { border-radius: 5px; }
.blocks_366.our-services-home .main_content_wrapper .block_01:before { -webkit-transform: perspective(500px) rotateY(-5deg); transform: perspective(500px) rotateY(-5deg); position: absolute; width: calc(100% + -7px); height: calc(100% + -13px); top: calc(25px + 7px); content: ""; border: 1px solid #7a7a7e; border-radius: 5px; z-index: -1; right: calc(25px - -7px); opacity: 0.2; visibility: visible; box-shadow: 0 0 1px transparent; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blocks_366.our-services-home .main_content_wrapper .block_01 .block_text.content_heading { padding-left: 10px; padding-right: 10px; }
/*Line button Animation*/
.more-btn.line-btn .btn { position: relative; padding: 0.459em 2.29em; line-height: 1.5; -webkit-transition: -webkit-transform 0.5s ease; transition: -webkit-transform 0.5s ease;  transition: transform 0.5s ease; transition: transform 0.5s ease, -webkit-transform 0.5s ease; }
.more-btn.line-btn .btn:before, .more-btn.line-btn .btn:after { content: ''; position: absolute; height: 1px; width: 0; background-color: #000; }
.more-btn.line-btn .btn:before { top: -1px; left: 0; -webkit-transition: width 0.15s 0.45s cubic-bezier(0.4, 0, 0.2, 1); transition: width 0.15s 0.45s cubic-bezier(0.4, 0, 0.2, 1); }
.more-btn.line-btn .btn span:before { left: 0;  bottom: 0; -webkit-transition: height 0.15s 0 cubic-bezier(0.4, 0, 0.2, 1); transition: height 0.15s 0 cubic-bezier(0.4, 0, 0.2, 1); }
.more-btn.line-btn .btn span:before { content: ''; position: absolute; height: 0; width: 1px; background-color: #000; -webkit-transition: all 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9); transition: all 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9); top: 0; right: 0;}
.more-btn.line-btn .btn span:after { content: ''; position: absolute; height: 0; width: 1px; background-color: #000; -webkit-transition: all 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9); transition: all 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9); top: 0; right: 0;}
.more-btn.line-btn .btn:hover:before { width: 100%; max-width: 100%;  -webkit-transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1); transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
.more-btn.line-btn .btn:hover:after { left: 0; bottom: -1px; width: 100%; -webkit-transition: width 0.15s 0.3s cubic-bezier(0.4, 0, 0.2, 1); transition: width 0.15s 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
.more-btn.line-btn .btn:hover span:before { left: -1px; height: 100%; -webkit-transition: height 0.15s 0.45s cubic-bezier(0.4, 0, 0.2, 1); transition: height 0.15s 0.45s cubic-bezier(0.4, 0, 0.2, 1); }
.more-btn.line-btn .btn:hover span:after { top: 0; right: -1px; height: 100%; -webkit-transition: height 0.15s 0.15s cubic-bezier(0.4, 0, 0.2, 1); transition: height 0.15s 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
.more-btn.line-btn .btn-outline-dark { color: inherit; border-color: inherit; }
.more-btn.line-btn .btn-outline-dark:hover { color: inherit; background-color: #ffffff; border: 1px solid #fff; transition: all 0.2s ease-in-out; }
.our-locations .more-btn.line-btn .btn {border: 0;}
.more-btn.line-btn { position: relative; }
/*.more-btn.line-btn::after { content: ''; position: absolute; height: 100%; width: 15px; top: 50px; bottom: 0; left: 0; right: 0; margin: auto; transform: rotate(30deg); background: #fff; z-index: 0; }
.more-btn.line-btn .btn span { z-index: 1; position: relative; }*/
/*Subscribe to our newsletter button Animation*/
.newsletter .container .row .subs-news:hover:before { width: 0; -webkit-transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1); transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
.newsletter .subs-news .more-btn.line-btn .btn { background-color: transparent; border: none; margin-left: 30px; padding: 10px 30px; transition: all 0.2s ease-in-out; }
.newsletter .subs-news:hover .more-btn.line-btn .btn { margin-left: 0; }
.newsletter .subs-news .more-btn.line-btn .btn:before,
.newsletter .subs-news .more-btn.line-btn .btn:after {width: 1px;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;}
.newsletter .subs-news .more-btn.line-btn .btn:before {top: 0;left: 0;}
.newsletter .subs-news .more-btn.line-btn .btn:after {right: 0;bottom: 0;left: inherit;}
.newsletter .subs-news:hover .more-btn.line-btn .btn:before,
.newsletter .subs-news:hover .more-btn.line-btn .btn:after{height: 100%;background-color: #fff;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out; }
.newsletter .subs-news .more-btn.line-btn .btn span:before,
.newsletter .subs-news .more-btn.line-btn .btn span:after {background-color: #fff;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out; }
.newsletter .subs-news .more-btn.line-btn .btn span:before { height: 1px;width:0;} 
.newsletter .subs-news .more-btn.line-btn .btn span:after {height: 1px;width: 0;bottom: 0;top: inherit;}
.newsletter .subs-news:hover .more-btn.line-btn .btn span:before, 
.newsletter .subs-news:hover .more-btn.line-btn .btn span:after {width: 100%;background-color: #fff;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out; }
.newsletter .subs-news .more-btn.line-btn .btn-outline-dark:hover { background-color: transparent; border: none;}
/* StartAbout Details Page*/
.about-details-page .about-hero { height: 100%; }
.about-details { padding-top: 50px; }
.about-details h2 {font-size: 60px;font-weight: 400;text-transform: uppercase;background: -webkit-linear-gradient(rgb(177, 177, 177), rgb(255, 255, 255));-webkit-background-clip: text;-webkit-text-fill-color: transparent;margin-bottom: 30px;text-align: center;}
.about-details h2 span {display: block;background: none;-webkit-background-clip: inherit;-webkit-text-fill-color: black;font-weight: 200;font-size:30px;margin-top: -30px;text-align: center;letter-spacing: 0px;}
.our-teams h2 {font-size: 60px;font-weight: 400;text-transform: uppercase;background: -webkit-linear-gradient(rgb(177, 177, 177), rgb(255, 255, 255));-webkit-background-clip: text;-webkit-text-fill-color: transparent;margin-bottom: 30px;text-align: center;}
.our-teams h2 span {display: block;background: none;-webkit-background-clip: inherit;-webkit-text-fill-color: black;font-weight: 200;font-size:30px;margin-top: -30px;text-align: center;letter-spacing: 0px;}
.about-details p {font-size: 18px;font-weight: 200;}
.about-details .list-inline {position: relative;}
.about-details .list-inline > li {text-transform: uppercase;padding: 0px 10px;border-right: 1px solid #000;font-size: 20px;}
.about-details .list-inline > li:last-child {border-right: none;}
.mission-sec h2 span {font-size: 30px;}
.mission-sec .red-text > h4 {color: #a22035;font-weight: 600;}
.mission-sec h4 {font-weight: 600;}
/*Team member list */
.our-team-members_sec h2 {font-weight: 200;}
.team-list-dep {position: relative;display: block;width: 100%;margin-bottom: 50px;}
.team-list-dep > h3 {margin-bottom: 30px;padding-top: 10px;position: relative;}
.team-list-dep > h3:before {content: '';border-top: 1px solid #000;position: absolute;width: 50%;top: 0;}
.team-list-dep ul {list-style: none;padding: 0;}
.team-list-dep ul > li {margin-bottom: 1.5rem;}
.team-list-dep ul > li > a {border: 1px solid #000;display: flex;height: 100%;justify-content: center;align-items: center;color: #000;text-decoration: none;text-align: center;}
.team-list-dep ul > li > a h5{font-size: 18px;}
/* Form Css */
.contact-form-section { margin-top: 25px; }
.contact-form-section .title { font-weight: 600; font-size: 1.5rem; margin-bottom: 25px; }
.contact-form-section .form-group { margin-bottom: 15px; }
.contact-form-section label { display: flex; align-items: center; justify-content: flex-start; padding-left: 0px; }
.contact-form-section .terms-text { }
.contact-form-section .contact-form { display: inline-block; width: 100%; padding: 0px; }
.contact-form-section .contact-form .form-control { background: #e6e6e6; border: 0; border-radius: 0px; }
.contact-form-section .contact-form .form-control:focus { outline: 0; box-shadow: none; }
.contact-form-section .submit-btn-holder .submit-btn { background: #a32035; min-width: 250px; color: #fff; border: 0px; padding: 10px 0;
border-radius: 5px; font-size: 16px; margin-bottom: 15px; border: 2px solid #a32035; }
.contact-form-section .submit-btn-holder .submit-btn:hover { border: 2px solid #a32035; background: transparent; color: #a32035; }
.contact-form-section .name-group { display: flex; flex-direction: row; }
.contact-form-section .name-group label{ width: 85px; }
.contact-form-section .email-project-group { display: flex; flex-direction: row; }
.contact-form-section .email-group, .contact-form-section .project-group { display: flex; flex-direction: row; }
.contact-form-section .project-group label { width: 100px; margin-left: 15px; }
.contact-form-section .project-group input { width: 140px; }
.contact-form-section .email-group label { width: 100px; }
.contact-form-section .message-group label { padding-left: 0px; padding-bottom: 15px; }
.contact-form-section .message-group textarea { resize: none; height: 100px; }
.error { display: none; color: #dd1117; font-size: 11px; padding-bottom: 5px; }
.success { display: none; padding: 10px; color: #dd1117; font-size: 12px; }
.property-detail .proprty-image .topic-heading { display: flex; align-items: center; justify-content: center; flex-direction: column; }
.property-detail .proprty-image .topic-heading .image-heading { max-width: 450px; }
.property-detail .proprty-image .topic-heading a { color: #a32035; font-size: 20px; font-weight: 400; text-decoration: none; padding: 10px; }
/* Main CSS */
.grid-wrapper { margin-top: 20px; }
.grid-wrapper > div { display: block; justify-content: center; align-items: center; }
.grid-wrapper > div > img { width: 100%; height: 100%; /*object-fit: cover;*/ border-radius: 5px; }
.grid-wrapper { display: grid; grid-gap: 12px; grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); grid-auto-rows: 200px; 	grid-auto-flow: dense; }
.grid-wrapper .wide { grid-column: span 2; }
.grid-wrapper .tall { grid-row: span 2; }
.grid-wrapper .big { grid-column: span 2; grid-row: span 2; }
/****** 07-0-2022 ***/
.grid-gallery { display: flex; flex-wrap: wrap; }
div#masnory { border-radius: 0px; background-size: cover; background-position: top center; margin: 0.3em; height: 34em; width: 34em; flex-grow: 1; }
div#masnory:nth-child(2n) { width: 24em; }
div#masnory:nth-child(2n) { width: 21em; }
div#masnory:nth-child(4n) { width: 16em; }
div#masnory:nth-child(5n) { width: 18em; }
div#masnory:nth-child(6n) { width: 22em; }
.teamsli li { position: relative; min-height:263px }
.team-list-dep h3{font-size:1.6rem}
.teamsli li .element {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #808080;
	font-weight:600;
	font-size:20px
}
.maroon { color: #a32035 !important; }
.red { color:#ff0000; }
.black-head {
	color: #3e3e3f !important;
	text-decoration: none
}

/* cookies css start */
.cookies-bottom {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	display: inline-block;
	z-index: 1024;
	padding: 20px 0;
	background: #000;
}
.cookies-inner-block {
	display: flex;
	align-items: center;
}
.cookies-text p, .cookies-text a {
	color: #fff;
	text-decoration: none;
	margin: 0;
	font-size: 14px;
	padding-right: 70px;
}
.close-cookies-box {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 30px;
	height: 30px;
	background: #fff;
	border-radius: 3px;
	text-align: center;
	line-height: 28px;
	color: #333;
	font-size: 18px;
	border: 1px solid #fff;
}
.close-cookies-box {
	cursor: pointer;
}
.accept-cookies a {
	padding-top: 15px;
	padding-bottom: 15px;
	border: 1px solid #a41a32;
	font-size: 14px;
	border-radius: 5px;
	display: block;
	min-width: 185px;
	text-align: center;
	background: #a41a32;
	color: #fff;
	text-decoration: none;
}
.accept-cookies a:hover {
	background: #000;
	color: #a41a32;
	border: 1px solid #a41a32;
}
.cookies-bottom.displaynone {
	visibility: hidden;
}
.cookies-btn {
	color: #000;
}
.cookies-btn:hover {
	color: #a41a32;
}
/* cookies css end */